import { useState } from 'react';

const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const handleModalState = () => {
    setShowModal(!showModal);
  };

  const [storeModal, setStoreModal] = useState(false);
  const handleStoreModal = () => {
    setStoreModal(!storeModal);
  };

  const [categoryModal, setCategoryModal] = useState(false);
  const handleCategoryModal = () => {
    setCategoryModal(!categoryModal);
  };

  const [gstModal, setGstModal] = useState(false);
  const handleGstModal = () => {
    setGstModal(!gstModal);
  };

  const [marginModal, setMarginModal] = useState(false);
  const handleMarginModal = () => {
    setMarginModal(!marginModal);
  };

  const [orderModal, setOrdersModal] = useState(false);
  const handleOrdersModal = () => {
    setOrdersModal(!orderModal);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const [viewItemModal, setViewItemModal] = useState(false);
  const handleViewItemModal = () => {
    setViewItemModal(!viewItemModal);
  };

  const [viewProductModal, setviewProductModal] = useState(false);
  const handleViewProductModal = () => {
    setviewProductModal(!viewProductModal);
  };

  const [viewVideoModal, setViewVideoModal] = useState(false);
  const handleViewVideoModal = () => {
    setViewVideoModal(!viewVideoModal);
  };

  const [viewRecordedModal, setViewRecordedModal] = useState(false);
  const handleViewRecordedModal = () => {
    setViewRecordedModal(!viewRecordedModal);
  };

  const [filterDrawer, setFilterDrawer] = useState(false);
  const handleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
  };

  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const handleSubCategoryModal = () => {
    setSubCategoryModal(!subCategoryModal);
  };

  const [initateRefundModal, setInitiateRefundModal] = useState(false);
  const handleInitiateRefundModal = () => {
    setInitiateRefundModal(!initateRefundModal);
  };

  const [refundModal, setRefundModal] = useState(false);
  const handleOpenRefundModal = () => {
    setRefundModal(!refundModal);
  };

  const [rejectRefundModal, setRejectRefundModal] = useState(false);
  const handleRejectRefundModal = () => {
    setRejectRefundModal(!rejectRefundModal);
  };

  const [showCreditNotesModal, setShowCreditNotesModal] = useState(false);
  const handleCreditNotesModal = () => {
    setShowCreditNotesModal(!showCreditNotesModal);
  };

  const [creditNotesConfimationModal, setCreditNotesConfirmationModal] = useState(false);
  const handleCreditNotesConfirmationModal = () => {
    setCreditNotesConfirmationModal(!creditNotesConfimationModal);
  };

  const [showFullScreenMediaModal, setShowFullScreenMediaModal] = useState(false);
  const handleFullScreenMediaModal = () => {
    setShowFullScreenMediaModal(!showFullScreenMediaModal);
  };

  const [showFullScreenCarouselModal, setShowFullScreenCarouselModal] = useState(false);
  const handleFullScreenCarouselModal = () => {
    setShowFullScreenCarouselModal(!showFullScreenCarouselModal);
  };

  const [showAddStateGstModal, setShowAddGstModal] = useState(false);
  const handleShowStateGstModal = () => {
    setShowAddGstModal(!showAddStateGstModal);
  };

  const [showSalesPersonModal, setShowSalesModal] = useState(false);
  const handleShowSalesPersonModal = () => {
    setShowSalesModal(!showSalesPersonModal);
  };

  const [channelPartnerModal, setChannelPartnerModal] = useState(false);
  const handleChannelPartnerModal = () => {
    setChannelPartnerModal(!channelPartnerModal);
  };

  return {
    showModal,
    handleModalState,
    storeModal,
    handleStoreModal,
    categoryModal,
    handleCategoryModal,
    gstModal,
    handleGstModal,
    marginModal,
    handleMarginModal,
    orderModal,
    handleOrdersModal,
    confirmationModal,
    handleConfirmationModal,
    viewItemModal,
    handleViewItemModal,
    viewProductModal,
    handleViewProductModal,
    viewVideoModal,
    handleViewVideoModal,
    viewRecordedModal,
    handleViewRecordedModal,
    filterDrawer,
    handleFilterDrawer,
    subCategoryModal,
    handleSubCategoryModal,
    initateRefundModal,
    handleInitiateRefundModal,
    refundModal,
    handleOpenRefundModal,
    rejectRefundModal,
    handleRejectRefundModal,
    showCreditNotesModal,
    handleCreditNotesModal,
    creditNotesConfimationModal,
    handleCreditNotesConfirmationModal,
    showFullScreenMediaModal,
    handleFullScreenMediaModal,
    showFullScreenCarouselModal,
    handleFullScreenCarouselModal,
    showAddStateGstModal,
    handleShowStateGstModal,
    showSalesPersonModal,
    handleShowSalesPersonModal,
    channelPartnerModal,
    handleChannelPartnerModal
  };
};

export default useModal;
