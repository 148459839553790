import { Modal, Slide, Box, IconButton, Typography, CardMedia, useTheme } from '@mui/material';
import { Carousel } from 'react-material-ui-carousel';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useQuery } from 'react-query';
import moment from 'moment';
import getS3Bucket from '../../utils/getS3Bucket';
import styles from './channelPartnerDetails.module.scss';
import { GetAllChannelPartnerDetails } from '../../api/GetAllChannelPartnerDetails';

const ChannelPartnerDetailsModal = ({ isOpen, handleChannelPartnerModal, partnerId }) => {
  const theme = useTheme();
  const getStatusClass = (isApproved) => {
    if (isApproved === undefined) return styles.pending;
    return isApproved ? styles.approved : styles.disapproved;
  };
  const { data, isLoading, refetch } = useQuery(
    ['GetAllChannelPartnerDetails', partnerId, isOpen],
    () => GetAllChannelPartnerDetails(partnerId),
    {
      refetchOnWindowFocus: false,
      enabled: isOpen,
    }
  );
  const partnerData = data?.data?.data;
  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.boxContainer}>
          <Box className={styles.modalBody}>
            <IconButton className={styles.closeBtn} onClick={() => handleChannelPartnerModal(false)}>
              <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 25 }} />
            </IconButton>

            <Typography className={styles.modalTitle}>Details</Typography>
            {!isLoading && (
              <Box className={styles.contentContainer}>
                <Box className={styles.contentRow}>
                  {!partnerData?.isOrganization && (
                    <Box className={styles.valueWrapper}>
                      <Typography className={styles.title}>Title</Typography>
                      <Typography className={styles.value}>{partnerData?.title}</Typography>
                    </Box>
                  )}
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>
                      {partnerData?.isOrganization ? 'Organization Name' : 'Name'}
                    </Typography>
                    <Typography className={styles.value}>{partnerData?.partnerName}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                {partnerData?.isOrganization && (
                  <>
                    <Box className={styles.contentRow}>
                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>Type of Organization</Typography>
                        <Typography className={styles.value}>{partnerData?.organizationType}</Typography>
                      </Box>
                    </Box>
                    <Box className={styles.divider} />
                  </>
                )}
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Type</Typography>
                    <Typography className={styles.value}>
                      {partnerData?.isOrganization ? 'Enterprise' : 'Individual'}
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Email</Typography>
                    <Typography className={styles.value}>{partnerData?.partnerEmail}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Contact number</Typography>
                    <Typography className={styles.value}>{partnerData?.partnerMobile}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>State</Typography>
                    <Typography className={styles.value}>{partnerData?.state}</Typography>
                  </Box>

                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>City</Typography>
                    <Typography className={styles.value}>{partnerData?.city}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Address</Typography>
                    <Typography className={styles.value}>{partnerData?.address}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Postal code</Typography>
                    <Typography className={styles.value}>{partnerData?.pincode}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                {partnerData?.isOrganization && (
                  <>
                    <Box className={styles.contentRow}>
                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>Title</Typography>
                        <Typography className={styles.value}>{partnerData?.title}</Typography>
                      </Box>

                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>Owner Name</Typography>
                        <Typography className={styles.value}>{partnerData?.ownerName}</Typography>
                      </Box>
                    </Box>
                    <Box className={styles.divider} />
                    <Box className={styles.contentRow}>
                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>Designation</Typography>
                        <Typography className={styles.value}>{partnerData?.ownerDesignation}</Typography>
                      </Box>
                    </Box>
                    <Box className={styles.divider} />
                    <Box className={styles.contentRow}>
                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>Contact Person Name</Typography>
                        <Typography className={styles.value}>{partnerData?.contactPersonName}</Typography>
                      </Box>
                    </Box>
                    <Box className={styles.divider} />
                    <Box className={styles.contentRow}>
                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>Contact Person Number</Typography>
                        <Typography className={styles.value}>{partnerData?.contactPersonMobile}</Typography>
                      </Box>
                    </Box>
                    <Box className={styles.divider} />
                  </>
                )}
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Status</Typography>
                    <Typography className={`${styles.value} ${getStatusClass(partnerData?.isApprovedByByzwiz)}`}>
                      {partnerData?.isApprovedByByzwiz === undefined && 'Pending'}
                      {partnerData?.isApprovedByByzwiz === true && 'Approved'}
                      {partnerData?.isApprovedByByzwiz === false && 'Disapproved'}
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Application Date</Typography>
                    <Typography className={styles.value}>
                      {moment(partnerData?.createdAt)?.format('DD-MM-YYYY HH:MM')}
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>Aadhar Number</Typography>
                    <Typography className={styles.value}>{partnerData?.aadhar?.number}</Typography>
                  </Box>
                </Box>
                <Box className={styles.divider} />
                {partnerData?.gst?.gstNumber && (
                  <>
                    <Box className={styles.contentRow}>
                      <Box className={styles.valueWrapper}>
                        <Typography className={styles.title}>GST Number</Typography>
                        <Typography className={styles.value}>{partnerData?.gst?.gstNumber}</Typography>
                      </Box>
                    </Box>
                    <Box className={styles.divider} />
                  </>
                )}
                <Box className={styles.contentRow}>
                  <Box className={styles.valueWrapper}>
                    <Typography className={styles.title}>E-Sign Status</Typography>
                    <Typography className={styles.value}>Completed</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ChannelPartnerDetailsModal;
