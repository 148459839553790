import client from './Client';

const endPoint = '/su/channel-partner/generate-invoices';

const GenerateChannelPartnerInvoices = () => {
  const token = localStorage.getItem('token');

  return client.post(
    endPoint,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { GenerateChannelPartnerInvoices };
