import client from './Client';

const endPoint = '/su/channel-partner/';

const ValidateChannelPartner = (partnerId,result) => {
  const token = localStorage.getItem('token');
  return client.put(
    endPoint + partnerId,
    {result},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { ValidateChannelPartner };
