import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DashboardActiveIcon } from '../../assets/icons/dashboardActive.svg';

import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as UserActiveIcon } from '../../assets/icons/userActiveIcon.svg';

import { ReactComponent as InvitationRequest } from '../../assets/icons/invitationRequest.svg';
import { ReactComponent as InvitationRequestActiveIcon } from '../../assets/icons/invitationRequestActive.svg';

import { ReactComponent as BusinessIcon } from '../../assets/icons/business.svg';
import { ReactComponent as BusinessActiveIcon } from '../../assets/icons/businessActive.svg';

import { ReactComponent as CategoryIcon } from '../../assets/icons/categories.svg';
import { ReactComponent as CategoryActiveIcon } from '../../assets/icons/caegoryActiveIcon.svg';

import { ReactComponent as GstIcon } from '../../assets/icons/gst.svg';
import { ReactComponent as GstActiveIcon } from '../../assets/icons/gstActiveIcon.svg';

import { ReactComponent as MarginIcon } from '../../assets/icons/margin.svg';
import { ReactComponent as MarginActiveIcon } from '../../assets/icons/marginActiveIcon.svg';

import { ReactComponent as CarouselIcon } from '../../assets/icons/carousel.svg';
import { ReactComponent as CarouselActiveIcon } from '../../assets/icons/carouselActiveIcon.svg';

import { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg';
import { ReactComponent as OrdersActiveIcon } from '../../assets/icons/orderActiveIcon.svg';

import { ReactComponent as InvoiceIcon } from '../../assets/icons/invoice.svg';
import { ReactComponent as InvoiceActiveIcon } from '../../assets/icons/invoiceActive.svg';

import { ReactComponent as PaymentIcon } from '../../assets/icons/payments.svg';
import { ReactComponent as PaymentActiveIcon } from '../../assets/icons/paymentsActive.svg';

import { ReactComponent as FinanicialsIcon } from '../../assets/icons/financials.svg';
import { ReactComponent as FinanicialsActiveIcon } from '../../assets/icons/financialsActive.svg';

import { ReactComponent as FlagIcon } from '../../assets/icons/flag.svg';
import { ReactComponent as FlagActiveIcon } from '../../assets/icons/flagActive.svg';

import { ReactComponent as FaqIcon } from '../../assets/icons/faq.svg';
import { ReactComponent as FaqActiveIcon } from '../../assets/icons/faqActive.svg';

import { ReactComponent as Subcategory } from '../../assets/icons/subCategory.svg';
import { ReactComponent as SubcategoryActiveIcon } from '../../assets/icons/subCategoryActiveIcon.svg';

import { ReactComponent as RefundIcon } from '../../assets/icons/refund.svg';
import { ReactComponent as RefundActiveIcon } from '../../assets/icons/refundActive.svg';

import { ReactComponent as PropagationInActive } from '../../assets/icons/propagationInActive.svg';
import { ReactComponent as PropagationActive } from '../../assets/icons/propagationActive.svg';

import { ReactComponent as SalesPersonInActive } from '../../assets/icons/salesPersonInActive.svg';
import { ReactComponent as SalesPersonActive } from '../../assets/icons/salesPersonActive.svg';

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
    divider: true,
  },
  {
    title: 'Channel Partners',
    path: '/dashboard/channel-partners',
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
    divider: true,
  },
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: <UserIcon />,
    activeIcon: <UserActiveIcon />,
  },
  {
    title: 'Businesses',
    path: '/dashboard/stores',
    icon: <BusinessIcon />,
    activeIcon: <BusinessActiveIcon />,
  },
  {
    title: 'Invitation Requests',
    path: '/dashboard/invitation-requests',
    icon: <InvitationRequest />,
    activeIcon: <InvitationRequestActiveIcon />,
    divider: true,
  },
  {
    title: 'Categories',
    path: '/dashboard/category',
    icon: <CategoryIcon />,
    activeIcon: <CategoryActiveIcon />,
  },
  {
    title: 'Sub-Categories',
    path: '/dashboard/subcategories',
    icon: <Subcategory />,
    activeIcon: <SubcategoryActiveIcon />,
  },
  {
    title: 'GST',
    path: '/dashboard/gst',
    icon: <GstIcon />,
    activeIcon: <GstActiveIcon />,
  },
  {
    title: 'State GST',
    path: '/dashboard/state-gst',
    icon: <GstIcon />,
    activeIcon: <GstActiveIcon />,
  },
  {
    title: 'Margin',
    path: '/dashboard/margin',
    icon: <MarginIcon />,
    activeIcon: <MarginActiveIcon />,
  },
  {
    title: 'Carousel',
    path: '/dashboard/carousel',
    icon: <CarouselIcon />,
    activeIcon: <CarouselActiveIcon />,
    divider: true,
  },
  {
    title: 'Orders',
    path: 'dashboard/orders',
    icon: <OrdersIcon />,
    activeIcon: <OrdersActiveIcon />,
    divider: true,
  },
  {
    title: 'Propagation',
    path: 'dashboard/propagation',
    icon: <PropagationInActive />,
    activeIcon: <PropagationActive />,
    divider: false,
  },
  {
    title: 'Sales Person',
    path: 'dashboard/sales-person',
    icon: <SalesPersonInActive />,
    activeIcon: <SalesPersonActive />,
    divider: true,
  },
  {
    title: 'Invoices',
    path: 'dashboard/invoices',
    icon: <InvoiceIcon />,
    activeIcon: <InvoiceActiveIcon />,
  },
  {
    title: 'Payments',
    path: 'dashboard/payments',
    icon: <PaymentIcon />,
    activeIcon: <PaymentActiveIcon />,
  },
  {
    title: 'Financials',
    path: 'dashboard/financial',
    icon: <FinanicialsIcon />,
    activeIcon: <FinanicialsActiveIcon />,
  },
  {
    title: 'Refund',
    path: 'dashboard/refund',
    icon: <RefundIcon />,
    activeIcon: <RefundActiveIcon />,
    divider: true,
  },
  {
    title: 'Flags',
    path: 'dashboard/flags',
    icon: <FlagIcon />,
    activeIcon: <FlagActiveIcon />,
  },
  {
    title: 'FAQs',
    path: 'dashboard/faq',
    icon: <FaqIcon />,
    activeIcon: <FaqActiveIcon />,
  },
];

export default navConfig;
