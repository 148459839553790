import client from './Client';

const endPoint = '/su/channel-partner/';

const GetAllChannelPartnerDetails = (partnerId) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint+partnerId,
    {  },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { GetAllChannelPartnerDetails };
