import client from './Client';

const endPoint = '/su/channel-partner';

const GetAllChannelPartners = (page, limit) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { page, limit },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { GetAllChannelPartners };
