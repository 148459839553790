import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Typography,
  Container,
  SvgIcon,
  TablePagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import ChannelPartnerDetailsModal from '../../components/ChannelPartnerDetailsModal/ChannelPartnerDetailsModal';
import { getStateGst } from '../../api/GetStateGst';
import { ValidateChannelPartner } from '../../api/ValidateChannelPartner';
import Scrollbar from '../../components/Scrollbar';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import Loader from '../../components/Loader/Loader';
import styles from '../../pageStyles/pageStyles.module.scss';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import AddSalesPersonModal from '../../components/AddSalesPersonModal/AddSalesPersonModal';
import { GetAllChannelPartnerCount } from '../../api/GetAllChannelPartnerCount';
import { GetAllChannelPartners } from '../../api/GetAllChannelPartners';
import { GenerateChannelPartnerInvoices } from '../../api/GenerateChannelPartnerInvoices';
import { ReactComponent as DiscardIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as SettleIcon } from '../../assets/icons/right.svg';

const ChannelPartners = () => {
  const theme = useTheme();
  const [partnerId, setPartnerId] = useState('');
  const [result, setResult] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { channelPartnerModal, handleChannelPartnerModal, confirmationModal, handleConfirmationModal } = useModal();

  const { data, isLoading, refetch } = useQuery(
    ['GetAllChannelPartners', page, limit],
    () => GetAllChannelPartners(page, limit),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: countData, refetch: refetchCount } = useQuery(
    ['GetAllChannelPartnerCount'],
    () => GetAllChannelPartnerCount(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const validateChannelPartnerMutation = useMutation(
    'ValidateChannelPartner',
    () => ValidateChannelPartner(partnerId, result),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
            refetch();
            refetchCount();
            // setPage(1);
          }
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const generateChannelPartnerInvoicesMutation = useMutation(
    'GenerateChannelPartnerInvoices',
    () => GenerateChannelPartnerInvoices(),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (countData?.data?.success && countData?.data?.data) {
      if (page > Math.ceil(countData?.data?.data / limit)) {
        setPage((page) => page - 1);
      }
    }
  }, [countData, limit]);

  return (
    <Page title="Gst">
      {!isLoading && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="flex-end" width="1000px" sx={{ marginBottom: '30px' }}>
            <Button
              className={styles.addButton}
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              onClick={() => {generateChannelPartnerInvoicesMutation.mutateAsync()}}
            >
              Approve all Invoices
            </Button>
          </Stack>

          {data?.data?.success ? (
            <Box className={styles.inviteDataWrapper}>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#F5F4F4' }}>
                      <TableCell align="left" width="260px" className={styles.inviteTableCell}>
                        Name
                      </TableCell>
                      <TableCell align="start" width="100px" className={styles.inviteTableCell}>
                        Type
                      </TableCell>
                      <TableCell align="start" width="140px" className={styles.inviteTableCell}>
                        Mobile Number
                      </TableCell>
                      <TableCell align="start" width="270px" className={styles.inviteTableCell}>
                        Address
                      </TableCell>
                      <TableCell align="start" width="120px" className={styles.inviteTableCell}>
                        Details
                      </TableCell>
                      <TableCell align="start" width="110px" className={styles.inviteTableCell}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {((data?.data?.success && data?.data?.data) || []).map((item, index) => {
                      return (
                        <TableRow key={index} className={index % 2 === 1 ? styles.darkBackground : ''}>
                          <TableCell className={styles.inviteTableDataCell} align="start" justifyContent="start">
                            {item?.partnerName}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="center">
                            {item?.isOrganization ? 'Enterprise' : 'Individual'}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.partnerMobile}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.address}
                          </TableCell>
                          <TableCell
                            className={styles.inviteTableDataCell}
                            align="center"
                            sx={{ color: '#6A46DB', cursor: 'pointer', alignContent: 'center' }}
                            onClick={() => {
                              handleChannelPartnerModal(true);
                              setPartnerId(item?._id);
                            }}
                          >
                            View Details
                          </TableCell>
                          <TableCell
                            className={styles.inviteTableDataCell}
                            align="start"
                            sx={{ borderRight: '0px !important', alignContent: 'center' }}
                          >
                            {item?.isApprovedByByzwiz === undefined ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Box
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setResult(false);
                                    setPartnerId(item?._id);
                                    handleConfirmationModal(true);
                                  }}
                                >
                                  <SvgIcon viewBox="0 0 24 24" sx={{ fontSize: 30 }}>
                                    <DiscardIcon />
                                  </SvgIcon>
                                </Box>
                                <Box
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setResult(true);
                                    setPartnerId(item?._id);
                                    handleConfirmationModal(true);
                                  }}
                                >
                                  <SvgIcon viewBox="0 0 24 24" sx={{ fontSize: 30 }}>
                                    <SettleIcon />
                                  </SvgIcon>
                                </Box>
                              </Box>
                            ) : (
                              <>{item?.isApprovedByByzwiz ? 'Approved' : 'Disapproved'}</>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={countData?.data?.success && countData?.data?.data ? countData?.data?.data : 0}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          ) : (
            <Box className={styles.noDataWrapper}>
              <Typography className={styles.noDataText}>No data found</Typography>
            </Box>
          )}
        </Container>
      )}

      {isLoading && <Loader isLoaderVisible />}
      <ChannelPartnerDetailsModal
        isOpen={channelPartnerModal}
        handleChannelPartnerModal={handleChannelPartnerModal}
        partnerId={partnerId}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={
          result
            ? 'Are you sure you want to approve this sales person?'
            : 'Are you sure you want to disapprove this sales person?'
        }
        onConfirm={() => {
          validateChannelPartnerMutation.mutateAsync();
        }}
      />
    </Page>
  );
};

export default ChannelPartners;
